/* html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
} */

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    background-color:  rgba(219, 217, 217, 0.25);
}

@media (max-width: 764px) {
    .container {
        width: 100%;
        padding: 5px; /* Optional: reduce padding on smaller screens */
    }
}

.message-container {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 30px;
    border-bottom-right-radius: 0;
}

.sent-message {
    background-color:white ;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
    width: 50%;
    margin-top: 1%;
    text-align: start;
    margin-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.received-message {
    width: 50% ;
    background-color:white;
    align-self: flex-start;
    color:black;
    margin: 0;
    margin-top: 1%;
    text-align: start;
    margin-left: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.message-text {
    margin: 0;
    padding: 5px;
    padding-top: 0;
    color: black;
}

.message-sender {
    font-size: 0.8em;
    color: #0097DB;
    margin: 0;
    width: 98%;
    padding: 2px;
    padding-top: 0;
    padding-left: 5px;
    font-size: 23;
}

.input-container {
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
    padding: 5px;
    background-color: white;
    width: 80%;
    border-radius: 50;
}

.emoji-button {
    background: none;
    border: none;
    font-size: 1.5em;
}

.input {
    flex: 1;
    padding: 5px;
    margin: 0 5px;
    /* border: 2px solid rgb(211, 211, 211, 0.25); */
}

.send-button {
    background: rgb(211, 211, 211, 0.25);
    color: black;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
}

.link-container {
    display: flex;
    text-decoration: none;
    color: inherit;
    margin-bottom: 10px;
}

.link-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.desc-container {
    flex: 1;
}

.link-title {
    font-weight: bold;
    margin: 0;
}

.link-description {
    margin: 0;
}

.emoji-picker-container {
    height: 40%;
}

.top-border{
    height: 60px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1%;
}

.uploaded-image {
    height: 200px;
    width: 300px;
    object-fit: contain;
}
